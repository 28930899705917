<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <img src="/img/logo/Logo-UNJ.png" alt="" height="100" />
            </div>

            <div class="d-flex justify-content-center">
              <h4>Surat Permohonan Validasi</h4>
            </div>
            <div class="d-flex justify-content-center">
              <h4>Program Studi Fisika, Pendidikan Fisika, dan Magister Pendidikan Fisika</h4>
            </div>
            <hr style="height: 1px; background: black" />

            <ul class="undot">
              <li>Nama Mahasiswa : {{ detail.student_name }}</li>
              <li>NIM : {{ detail.registration_number }}</li>
              <li>Semester ke- : {{ detail.semester_name }}</li>
              <li>Tahun ke- : {{ detail.year_id }}</li>
              <li>Program Studi : {{ detail.study_program_name }}</li>
              <li>Fakultas : {{ detail.faculty_name }}</li>
              <li>Alamat : {{ detail.address }}</li>
              <li>No. Telp/Wa : {{ detail.phone }}</li>
              <li>Alamat Email : {{ detail.email }}</li>
              <!--  -->
              <li>Dosen Pembimbing 1 : {{ detail.teacher_mentor1 }}</li>
              <li>Dosen Pembimbing 2 : {{ detail.teacher_mentor2 }}</li>
              <li>Judul Penelitian : {{ detail.title }}</li>
              <li>Nama Validator : {{ detail.validator }}</li>
              <li>Nama Instansi : {{ detail.institution_name }}</li>
              <li>Jenis Validasi : {{ detail.validation_name }}</li>
              <li>Nama Alat / Media Yang Akan Divalidasi : {{ detail.validated_tool_name }}</li>
              <!--  -->
              <li>Keterangan : {{ detail.description }}</li>
            </ul>

            <!-- <div class="d-flex justify-content-start mt-2">
              <div class="d-flex justify-content-start align-items-center image-container">
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="detail.sign" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
  },
};
</script>

<style scoped>
.undot li {
  list-style-type: none;
}

.image-input-wrapper {
  width: 130px !important;
  height: 130px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
